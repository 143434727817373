<template>
  <div class=" my-4">
    <div class="v16-description mb-4" style="color: #73738D;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 28px;"> Your Customers throughout the Globe!</div>
  <div class="card" style="border-radius: 8px;
border: 1px solid #EDEDED;


/* Shadow/sm */
box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);">
    <div
      class="card-body"
      
    >
      <div v-if="isLoading">
        <div
          class="bg-preload my-2"
          style="height: 20px; width: 100%; border-radius: 4px"
        >
          <div class="into-preload"></div>
        </div>
        <div
          class="bg-preload my-2"
          style="height: 400px; width: 100%; border-radius: 4px"
        >
          <div class="into-preload"></div>
        </div>
      </div>
      <div v-show="!isLoading">
        <div
          style="width: 100%; height: 500px"
          class="mapContainer"
          ref="worldMapContainer"
          id="worldMapContainer"
        ></div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "ChoroplethMap",
  props: ["mapData", "isLoading"],
  data() {
    return {
      loadedResources: [],
      resources: [
        {
          type: "script",
          src: "https://cdn.anychart.com/releases/v8/js/anychart-base.min.js",
        },
        {
          type: "script",
          src: "https://cdn.anychart.com/releases/v8/js/anychart-ui.min.js",
        },
        {
          type: "script",
          src: "https://cdn.anychart.com/releases/v8/js/anychart-exports.min.js",
        },
        {
          type: "script",
          src: "https://cdn.anychart.com/releases/v8/js/anychart-map.min.js",
        },
        {
          type: "script",
          src: "https://cdn.anychart.com/releases/v8/js/anychart-data-adapter.min.js",
        },
        {
          type: "script",
          src: "https://cdn.anychart.com/geodata/latest/custom/world/world.js",
        },
        {
          type: "script",
          src: "https://cdnjs.cloudflare.com/ajax/libs/proj4js/2.3.15/proj4.js",
        },
        {
          type: "link",
          href: "https://cdn.anychart.com/releases/v8/css/anychart-ui.min.css",
          rel: "stylesheet",
        },
        {
          type: "link",
          href: "https://cdn.anychart.com/releases/v8/fonts/css/anychart-font.min.css",
          rel: "stylesheet",
        },
      ],
      mapInstance: null,
    };
  },
  watch: {
    mapData: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.initMap();
        }
      },
    },
  },
  mounted() {
    this.isMapLoading = true;
    this.loadResources(this.resources)
      .then(async () => {
        this.initMap();
      })
      .catch((error) => {
        console.error("Resource loading failed:", error);
      });
  },
  beforeDestroy() {
    // this.removeScripts();
    this.removeResources();
  },
  methods: {
    async loadResources(resources) {
      for (let resource of resources) {
        await this.loadResource(resource);
      }
    },

    loadResource(resource) {
      return new Promise((resolve, reject) => {
        let element;
        if (resource.type === "script") {
          element = document.createElement("script");
          element.src = resource.src;
        } else if (resource.type === "link") {
          element = document.createElement("link");
          element.href = resource.href;
          element.rel = resource.rel;
        }
        element.onload = () => {
          this.loadedResources.push(element); // Store the loaded element
          resolve(element);
        };
        element.onerror = () =>
          reject(
            new Error(
              `Resource load error for ${resource.src || resource.href}`
            )
          );

        document.head.appendChild(element);
      });
    },
    removeResources() {
      this.loadedResources.forEach((element) => {
        if (document.head.contains(element)) {
          document.head.removeChild(element);
        }
      });
      this.loadedResources = []; // Clear the array after removing the elements
    },

    async initMap() {
      if (this.mapInstance) {
        this.mapInstance.dispose();
        this.mapInstance = null;
        const container = document.getElementById("worldMapContainer");
        if (container) {
          container.innerHTML = "";
        }
      }
      try {
        if (window.anychart) {
          let anychart = window.anychart;

          var map = anychart.map();

          map
            .credits()
            .enabled(true)
            .url("https://www.quizell.com")
            .logoSrc("https://www.quizell.com/favicon.png");

          map
            .title()
            .enabled(true)
            .useHtml(true)
            .padding([10, 0, 10, 0])
            .text("Your Customers throughout the Globe!<br/>");

          map.geoData("anychart.maps.world");
          map.interactivity().selectionMode("none");
          map.padding(0);

          var dataSet = anychart.data.set(this.mapData);
          var leadEfficiency = dataSet.mapAs({ value: "percentage" });
          var series = map.choropleth(leadEfficiency);

          series.labels(false);
          series.stroke(anychart.color.darken("#fff2cc"));
          series
            .hovered()
            .fill("#FFC000")
            .stroke(anychart.color.darken("#FFC000"));

          series
            .selected()
            .fill("#FFC000")
            .stroke(anychart.color.darken("#FFC000"));

          series
            .tooltip()
            .useHtml(true)
            .format(function () {
              return `<span style="color: #d9d9d9">Percent</span>: ${this.getData(
                "percentage"
              ).toLocaleString()}% <br/>
              <span style="color: #d9d9d9">Total Leads</span>: ${this.getData(
                "total_leads"
              ).toLocaleString()}
             `;
            });

          var scale = anychart.scales.ordinalColor([
            { from: 0, to: 10 },
            { from: 10, to: 20 },
            { from: 20, to: 30 },
            { from: 30, to: 40 },
            { from: 40, to: 50 },
            { from: 50, to: 60 },
            { from: 60, to: 70 },
            { from: 70, to: 80 },
            { from: 80, to: 90 },
            { from: 90, to: 100 },
          ]);
          scale.colors([
            "#fff2cc",
            "#ffecb2",
            "#ffe599",
            "#ffdf7f",
            "#ffdc72",
            "#ffd966",
            "#ffd24c",
            "#ffcc33",
            "#ffc619",
            "#ffc000",
          ]);

          var colorRange = map.colorRange();
          colorRange.enabled(true).padding([0, 0, 20, 0]);
          colorRange
            .ticks()
            .enabled(true)
            .stroke("3 #ffffff")
            .position("center")
            .length(7);
          colorRange.colorLineSize(5);
          colorRange.marker().size(7);
          colorRange
            .labels()
            .fontSize(11)
            .padding(3, 0, 0, 0)
            .format(function () {
              var range = this.colorRange;
              var name;
              if (isFinite(range.start + range.end)) {
                name = range.start + " - " + range.end;
              } else if (isFinite(range.start)) {
                name = "More than " + range.start;
              } else {
                name = "Less than " + range.end;
              }
              return name;
            });

          series.colorScale(scale);

          // create zoom controls
          var zoomController = anychart.ui.zoom();
          zoomController.render(map);

          map.container(`worldMapContainer`);
          map.draw();

          this.mapInstance = map;
        }
      } catch (error) {
        if (error) {
         error
        }
      }
    },
  },
};
</script>

<style scoped>
.mapContainer >>> a {
  display: none !important;
}
</style>
